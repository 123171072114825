<template>
  <span>
    <div class="section section__partners">
      <div class="section__wrapper" v-if="partners">
        <div class="title-with-icon mb-4">
          <div class="title-with-icon__icon title-with-icon__icon_partners"></div>
          <div class="title-with-icon__title">Партнеры</div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="section__partners-wrapper">
              <ul class="nav nav-tabs_underlined mb-5" id="myTabPartners" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="partners-tab" data-toggle="tab" href="#partners" role="tab" aria-controls="partners" aria-selected="false">Партнёры</a>
                </li>
                <li class="nav-tabs_underlined-indicator"></li>
              </ul>

              <div class="tab-content" id="myTabPartnersContent">
                <div class="tab-pane fade show active" id="partners" role="tabpanel" aria-labelledby="partners-tab">
                  <template v-for="(partner, index) in partners">
                    <div v-if="index > 4" :key="partner.id" class="section__partners-item container-shadow p-24 fill-white mb-24 d-flex">
                      <div class="section__partners-img"><img :src="partner.big_logo['500x']" /></div>
                      <div class="section__partners-content">
                        <div class="section__partners-title">
                          {{ partner.title }}
                        </div>
                        <a target="_blank" :href="partner.link" class="section__partners-target mb-24">
                          {{ partner.link }}
                        </a>
                        <div class="section__partners-text mb-1" v-html="partner.description"></div>
                        <div v-if="partner.cut_description && partner.showCut" class="section__partners-text mb-1 cut" v-html="partner.cut_description"></div>
                        <a v-if="partner.cut_description" @click.prevent="showCut(partner)" class="content_700 content_blue more-partners" href="#">
                          {{ partner.showCut ? 'Скрыть' : 'Подробнее' }}
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-7 mt-md-0">
            <h2 class="content_title mb-24">Контакты</h2>
            <div class="section__faq p-0">
              <nav class="header__navigation-menu">
                <ul>
                  <li><a href="mailto:info@exportedu.ru" class="header__navigation-menu-email">info@exportedu.ru</a></li>
                  <li><a href="tel:info@exportedu.ru" class="header__navigation-menu-phone">+7 (495) 937-4742</a></li>
                  <li><a href="#" class="header__navigation-menu-location">123610, Москва, Краснопресненская наб. 12, подъезд
                    9</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </span>
</template>

<script>
import ApiService from "../../services/api.service";
import PageFooter from "../components/PageFooter";

export default {
  name: "Partners",
  components: {PageFooter},
  data() {
    return {
      partners: null
    }
  },
  mounted() {
    ApiService.get('partners').then(res => {
      this.partners = res.data
      this.init()
    })
  },
  methods: {
    showCut(partner) {
      this.$set(partner, 'showCut', !partner.showCut);
    },
    init() {
      let tag = document.createElement("script");
      tag.setAttribute("src",'/js/jquery.min.js');
      document.head.appendChild(tag);
      tag.onload = () => {
        let util = document.createElement("script");
        util.setAttribute("src",'/js/util.js');
        document.head.appendChild(util);
        util.onload = () => {
          let tabs = document.createElement("script");
          tabs.setAttribute("src",'/js/tabs.js');
          document.head.appendChild(tabs);
          tabs.onload = () => {
            let tabHref = document.createElement("script");
            tabHref.setAttribute("src", '/js/tabhref.js');
            document.head.appendChild(tabHref);

            let mainTag = document.createElement("script");
            mainTag.setAttribute("src", '/js/menu.js');
            document.head.appendChild(mainTag);
          }
        }
      }
    }
  }
}
</script>

<style>
@media (max-width: 768px) {
  .content_blue {
    font-size: 100%;
  }
}
.more-partners {
  font-size: 16px;
}
</style>